import { DataGrid } from '@emburse/embark';

import { CARDS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { ISerializedCard } from '../../types/api/serialized_card';

interface UserCardListProps {
  cards: ISerializedCard[];
}

const cardColumns = [
  { field: 'id', hide: true },
  { field: 'name', headerName: 'Name', width: 250, sortable: false },
  { field: 'number', headerName: 'Number', flex: 1, sortable: false }
];

const UserCardList = ({ cards }: UserCardListProps) => {
  return (
    <DataGrid
      rows={cards}
      columns={cardColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={CARDS_PER_PAGE}
    />
  );
};

export { UserCardList };
