import { Link } from 'react-router-dom';
import { ServerDataGrid } from '@emburse/embark';
import { CellParams } from '@material-ui/data-grid';

import { CARDS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { useApi, usePagination } from '../../hooks';
import { listCardsForProgramAndCompany } from '../../api/routes';
import { ISerializedCard } from '../../types/api/serialized_card';
import { useMemo } from 'react';

interface ICardProgramListProps {
  companyId: string;
  cardProgramId: string;
}

function CardList({ companyId, cardProgramId }: ICardProgramListProps) {
  const { limit, offset, onPageChange } = usePagination(CARDS_PER_PAGE);
  const { items, loading } = useApi<ISerializedCard>(listCardsForProgramAndCompany(companyId, cardProgramId, limit, offset));

  const creditColumns = useMemo(() => ([
    { field: 'id', hide: true },
    {
      field: 'name', headerName: 'Card Name', width: 250, sortable: false,
      renderCell: function render(params: CellParams) {
        const cardId = params.getValue('id') as string;
        return (
          <Link to={`/companies/${companyId}/card_programs/${cardProgramId}/cards/${cardId}`}>{params.getValue('name')}</Link>
        );
      }
    },
    { field: 'user', headerName: 'User Name', width: 350, sortable: false, valueFormatter: (({ value }: any) => value?.name) },
    { field: 'state', headerName: 'State', width: 200, sortable: false }
  ]), [companyId, cardProgramId]);

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={creditColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
    />
  );
}

export { CardList };
