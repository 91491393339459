import { useState, useEffect, useCallback } from 'react';
import './Navigation.css';
import { TopNav, TopNavProps } from '@emburse/embark';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';

const Navigation = () => {
  const [topNavProps, setTopNavProps] =  useState<TopNavProps>({});
  const { logout, user } = useAuth0();

  const handleLogout = useCallback(() => {
    const logoutOptions: LogoutOptions = { returnTo: window.location.origin };
    logout(logoutOptions);
  }, [logout]);

  useEffect(() => {
    const userSection = {
      sections: [{
        items: [{ iconText: 'Logout', onClick: handleLogout }]
      }]
    };

    const headerProps: TopNavProps = {};

    headerProps.companyName = 'SVB';
    headerProps.userName = user.name;
    headerProps.logoUrl = `${process.env.REACT_APP_APP_HOST}/svb_logo.svg`;
    headerProps.homeUrl = process.env.REACT_APP_APP_HOST;
    headerProps.settingsMenu = userSection;
    setTopNavProps( headerProps );
  }, [handleLogout, user]);

  return (
    <TopNav {...topNavProps}/>
  );
};

export { Navigation };
