import { useState } from 'react';
import { ServerDataGrid } from '@emburse/embark';
import { SortItem } from '@material-ui/data-grid';

import { EXPENSE_TAGS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { ISerializedExpenseTagBucket } from  '../../types/api/serialized_expense_tag_bucket';
import { disabledFormatter } from '../../helpers/datagrid_formatters';
import { useApi, usePagination } from '../../hooks';
import { listExpenseTagBucketsForCompany } from '../../api/routes';

const tagBucketsColumns = [
  { field: 'id', hide: true },
  { field: 'name', headerName: 'Name', width: 240, sortable: false },
  { field: 'bucket_type', headerName: 'Field Type', width: 120, sortable: false },
  { field: 'input_type', headerName: 'Dropdown/text', width: 120, sortable: false },
  { field: 'accounting_sync_type', headerName: 'Mapped to Accounting field?', width: 240, sortable: false },
  { field: 'user_can_set_value', headerName: 'User Editable', width: 100, sortable: false },
  { field: 'disabled', headerName: 'Status', width: 100, valueFormatter: disabledFormatter, sortable: false },
  { field: 'scoped_by', headerName: 'Scoped By', width: 240, sortable: false },
  { field: 'items', headerName: 'Items', width: 100, sortable: false },
  { field: 'state', headerName: 'State', flex: 1, sortable: false }
];

interface IExpenseTagBucketListProps { companyId: string }

function CompanyExpenseTagBucketList({ companyId }: IExpenseTagBucketListProps) {
  const [order, setOrder] =  useState<SortItem>({ field: 'created_at', sort: 'desc' });
  const { limit, offset, onPageChange } = usePagination(EXPENSE_TAGS_PER_PAGE);
  const { items, loading } = useApi<ISerializedExpenseTagBucket>(listExpenseTagBucketsForCompany(companyId, limit, offset, order));

  const onSortChange = (sortData: SortItem[]) => {
    setOrder((sortData && sortData[0]) || null);
  };
  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={tagBucketsColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
      onSortChange={onSortChange}
    />
  );
}

export { CompanyExpenseTagBucketList };
