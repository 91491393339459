import { ServerDataGrid, Tooltip } from '@emburse/embark';
import { SortItem } from '@material-ui/data-grid';

import { ITEMS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { useApi, usePagination } from '../../hooks';
import { listTripsForUserProfile } from '../../api/routes';
import { ISerializedTrip } from '../../types/api/serialized_trip';

const userColumns = [
  { field: 'id', hide: true },
  {
    field: 'name', headerName: 'Name', flex: 1, sortable: false,
    renderCell: function render(params: any) {
      const name = params.getValue('name') as string;
      return (
        <Tooltip title={name}><span>{name}</span></Tooltip>
      );
    }
  },
  { field: 'created_at', headerName: 'Created On', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
  { field: 'started_at', headerName: 'Started On', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
  { field: 'ended_at', headerName: 'Ended On', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter }
];

const defaultSortModel: SortItem[] = [{
  field: 'created_at',
  sort: 'desc'
}];

type TripListProps = { companyId: string, userProfileId: string };

function TripList({ companyId, userProfileId }: TripListProps) {
  const { limit, offset, onPageChange } = usePagination(ITEMS_PER_PAGE);
  const { items, loading } = useApi<ISerializedTrip>(listTripsForUserProfile(companyId, userProfileId, limit, offset));

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={userColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
      defaultSortModel={defaultSortModel}
    />
  );
}

export { TripList };
