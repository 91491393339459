import { useMemo } from 'react';
import { useParams } from 'react-router';
import { CellParams } from '@material-ui/data-grid';
import { CurrencyFormattedDisplay, ServerDataGrid } from '@emburse/embark';

import { EXPENSES_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { ISerializedUnsubmittedExpense } from '../../types/api/serialized_unsubmitted_expense';
import { useApi, usePagination } from '../../hooks';
import { listUserUnsubmittedExpensesForCompany } from '../../api/routes';
import { ISerializedCardTransaction } from '../../types/api/serialized_card_transaction';

interface IUserExpensesProps { currency: string }

interface PageRouteParams {
  companyId: string,
  userId: string,
}

function UnsubmittedExpenseList({ currency }: IUserExpensesProps) {
  const { companyId, userId } = useParams<PageRouteParams>();
  const { limit, offset, onPageChange } = usePagination(EXPENSES_PER_PAGE);
  const { items, loading } = useApi<ISerializedUnsubmittedExpense>(listUserUnsubmittedExpensesForCompany(companyId, userId, limit, offset));

  const expenseColumns =  useMemo(() => ([
    { field: 'id', hide: true },
    { field: 'amount', headerName: 'Amount', sortable: false,
      renderCell: function render(params: CellParams) {
        const amount = params.getValue('amount') as number / 100;
        return <CurrencyFormattedDisplay amount={amount} code={currency} />;
      }
    },
    { field: 'created_at', headerName: 'Create Date', width: 240, sortable: false, valueFormatter: dateTimeFormatter },
    {
      field: 'card_transaction', headerName: 'Transaction', flex: 1,
      renderCell: function render(params: CellParams) {
        const transaction = params.getValue('card_transaction') as ISerializedCardTransaction;
        return (
          transaction ?
            <><CurrencyFormattedDisplay amount={transaction.amount} code={currency} />&nbsp;-&nbsp;{transaction.vendor}</> :
            <></>
        );
      }
    }
  ]), [currency]);

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={expenseColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
    />
  );
}

export { UnsubmittedExpenseList };
