import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CellParams, SortItem } from '@material-ui/data-grid';
import { ServerDataGrid, CurrencyFormattedDisplay, Grid } from '@emburse/embark';

import { CREDITS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { CSVExport } from '../Common/CSVExport';
import { formHeaders } from '../../helpers/csv';
import { CreditSource, ISerializedCredit } from '../../types/api/serialized_credit';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { useApi, usePagination } from '../../hooks';
import { listCreditsForUser } from '../../api/routes';
import { ListCreditsWhere } from '../../api/routes/credits';
import { Spacer } from '../Common/UI';
import { FilterBar } from '../Common/FilterBar';

interface ICreditListProps {
  companyId: string;
  userId: string;
  currency: string;
}

const UserCreditList = ({ companyId, userId, currency }: ICreditListProps) => {
  const [where, setWhere] = useState<ListCreditsWhere>({});
  const [order, setOrder] =  useState<SortItem>({ field: 'created_at', sort: 'desc' });
  const { limit, offset, onPageChange } = usePagination(CREDITS_PER_PAGE);
  const { items, loading } = useApi<ISerializedCredit>(listCreditsForUser(companyId, userId, limit, offset, where, order));

  const creditColumns = useMemo(() => [
    { field: 'id', hide: true },
    {
      field: 'amount', headerName: 'Amount', width: 140, sortable: false,
      renderCell: function render(params: CellParams) {
        const amount = params.getValue('amount') as number / 100;
        const creditId = params.getValue('id') as string ;
        return (
          <Link to={`/companies/${companyId}/users/${userId}/credits/${creditId}`}>
            <CurrencyFormattedDisplay amount={amount} code={currency} />
          </Link>
        );
      }
    },
    { field: 'created_at', headerName: 'Create Date', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
    { field: 'processor', headerName: 'Processor', width: 320, sortable: false },
    { field: 'source', headerName: 'Source', width: 320, sortable: false },
    { field: 'status', headerName: 'Status', flex: 1, sortable: false }
  ], [companyId, userId, currency]);

  const sourceOptions: { label: string; value: CreditSource; }[] = [
    { label: 'Reimbursement', value: 'reimbursement' },
    { label: 'Trial Deposit', value: 'trial_deposit' },
    { label: 'Bill Refund', value: 'bill_refund' },
    { label: 'Reimbursement Refund', value: 'reimbursement_refund' },
    { label: 'Payment', value: 'payment' }
  ];

  const onSortChange = (sortData: SortItem[]) => {
    setOrder((sortData && sortData[0]) || null);
  };

  const handleFilter = (source: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, source });
  };

  return (
    <>
      <Spacer />
      <Grid container justify="flex-end" spacing={2}>
        <Grid item>
          <FilterBar label="Filter by source" options={sourceOptions} onFilter={handleFilter} />
        </Grid>
      </Grid>
      <Spacer />
      <ServerDataGrid
        rowCount={items.count}
        rows={items.rows}
        columns={creditColumns}
        autoHeight={true}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        pageSize={limit}
        loading={loading}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
      />
      <CSVExport
        route={listCreditsForUser(companyId, userId, limit, offset, where, order)}
        headers={formHeaders(creditColumns)}
        title="Export Credits to CSV"
      />
    </>
  );
};

export { UserCreditList };
