import { SortItem } from '@material-ui/data-grid';

export type ListDebitsWhere = {
  source?: string;
}

export const listDebitsForCompany = (companyId: string, limit: number = 0, offset: number = 0, order: SortItem, where: ListDebitsWhere) => {
  const route = `/companies/${companyId}/debits`;

  let query = `?limit=${limit}&offset=${offset}`;

  if (order) {
    query += `&order=${order.field} ${order.sort}`;
  }

  if (where.source) {
    query += `&source=${where.source}`;
  }

  return `${route}${query}`;
};

export const getDebitForCompany = (companyId: string, debitId: string) => {
  return `/companies/${companyId}/debits/${debitId}?include[]=company&include[]=sync_record`;
};
