import { useState, ReactElement } from 'react';
import { Button } from '@emburse/embark';
import { GetApp } from '@material-ui/icons';
import { CSVDownload } from 'react-csv';
import { useAuth0 } from '@auth0/auth0-react';
import { callApi } from '../../../api';
import { Spacer } from '../UI';

type HeaderType = {
  key: string,
  label: string
}

type CSVExportProps = {
  route: string,
  headers?: HeaderType[],
  title?: string,
}

function CSVExport({ headers, title, route }: CSVExportProps) {
  const buttonTitle = title || 'Export to CSV';
  const { getAccessTokenSilently } = useAuth0();
  const [downloadEl, setDownloadEl] = useState<ReactElement>(<></>);

  const handleClick = async() => {
    const accessToken = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    const responseData = await callApi(accessToken, route);

    let rows = [];

    if (Array.isArray(responseData?.rows)) {
      rows = responseData.rows;
    } else if (Array.isArray(responseData)) {
      rows = responseData;
    }

    setDownloadEl(<CSVDownload data={rows} target="_blank" headers={headers}/>);
    setDownloadEl(<></>);
  };

  return (
    <>
      <Button startIcon={<GetApp />} onClick={handleClick}>{buttonTitle}</Button>
      {downloadEl}
      <Spacer />
    </>
  );
}

export { CSVExport };
