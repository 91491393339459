/* global HTMLInputElement */
import { TextField } from '@emburse/embark';
import debounce from 'lodash.debounce';

type SearchBarProps = {
  label: string
  onSearch: (text: string) => void
}

const SearchBar = ({ label, onSearch }: SearchBarProps) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const handleSearchDebounced = debounce(handleSearch, 500);

  return (
    <TextField
      id="search-bar"
      label={label}
      onChange={handleSearchDebounced}
    />
  );
};

export { SearchBar };
