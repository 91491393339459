import { DataGrid } from '@emburse/embark';
import { EXPENSE_RULES_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { ISerializedExpenseRule } from '../../types/api/serialized_expense_rule';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';

const rulesColumns = [
  { field: 'id', hide: true },
  { field: 'created_at', headerName: 'Created On', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
  { field: 'description', headerName: 'Rule', width: 350, sortable: false }
];

interface IRulesListProps {
  rulesString: string;
}

function RulesList({ rulesString }: IRulesListProps) {
  const rules: ISerializedExpenseRule[] = JSON.parse(rulesString);
  return (
    <DataGrid
      rows={rules}
      columns={rulesColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={EXPENSE_RULES_PER_PAGE}
    />
  );
}

export { RulesList };
