import { CurrencyFormattedDisplay, Grid, ServerDataGrid } from '@emburse/embark';
import { CellParams, SortItem } from '@material-ui/data-grid';

import { CARDS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { useApi, usePagination } from '../../hooks';
import { listTransactionsForProgramAndCompany } from '../../api/routes';
import { CardTransactionState, ISerializedCardTransaction } from '../../types/api/serialized_card_transaction';
import { useMemo, useState } from 'react';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { Spacer } from '../Common/UI';
import { SearchBar } from '../Common/SearchBar/SearchBar';
import { FilterBar } from '../Common/FilterBar';
import { ListTransactionsWhere } from '../../api/routes/cards';

interface ICardTransactionListProps {
  companyId: string;
  cardProgramId: string;
  cardId: string;
  currency: string;
}

const defaultSortModel: SortItem[] = [{
  field: 'created_at',
  sort: 'desc'
}];

const stateOptions: { label: string; value: CardTransactionState; }[] = [
  { label: 'New', value: 'new' },
  { label: 'Expensed', value: 'expensed' },
  { label: 'Skipped', value: 'skipped' },
  { label: 'Deleted', value: 'deleted' },
  { label: 'Duplicate', value: 'duplicate' }
];

const CardTransactionList = ({ companyId, cardProgramId, cardId, currency }: ICardTransactionListProps) => {
  const [where, setWhere] = useState<ListTransactionsWhere>({});
  const { limit, offset, onPageChange } = usePagination(CARDS_PER_PAGE);
  const { items, loading } = useApi<ISerializedCardTransaction>(listTransactionsForProgramAndCompany(companyId, cardProgramId, cardId, limit, offset, where));

  const cardTransactionColumns = useMemo(() => ([
    { field: 'id', hide: true },
    { field: 'created_at', headerName: 'Date', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
    { field: 'state', headerName: 'State', width: 200, sortable: false },
    {
      field: 'amount', headerName: 'Amount', width: 140, sortable: false,
      renderCell: function render(params: CellParams) {
        const amount = params.getValue('amount') as number / 100;
        return (
          <CurrencyFormattedDisplay amount={amount} code={currency} />
        );
      }
    },
    { field: 'vendor', headerName: 'Vendor', width: 300, sortable: false },
    { field: 'original_vendor', headerName: 'Original Vendor', width: 300, sortable: false }
  ]), [currency]);

  const handleSearch = (searchQuery: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, original_vendor: searchQuery });
  };

  const handleFilter = (state: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, state });
  };

  return (
    <>
      <Spacer />
      <Grid container justify="space-between" spacing={2}>
        <Grid item justify="flex-start">
          <SearchBar onSearch={handleSearch} label="Original Vendor"/>
        </Grid>
        <Grid item>
          <FilterBar label="Filter by state" options={stateOptions} onFilter={handleFilter} />
        </Grid>
      </Grid>
      <Spacer />
      <ServerDataGrid
        rowCount={items.count}
        rows={items.rows}
        columns={cardTransactionColumns}
        autoHeight={true}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        pageSize={limit}
        loading={loading}
        allowSelection={false}
        onPageChange={onPageChange}
        defaultSortModel={defaultSortModel}
      />
    </>
  );
};

export { CardTransactionList };
