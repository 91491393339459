import { Grid, Typography } from '@emburse/embark';

import { displayDateTime } from '../../helpers/text_formatters';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { ISerializedAccountingSyncRecord } from '../../types/api/serialized_accounting_sync_record';

type SyncRecordProps = {
  syncRecord: ISerializedAccountingSyncRecord;
  title?: string;
}

const SyncRecord = ({ syncRecord, title = 'Accounting Sync' }: SyncRecordProps) => {
  const {
    service,
    state,
    last_sync_attempt_at
  } = syncRecord;

  return (
    <>
      <Grid container direction="row" spacing={5} wrap="wrap">
        <Grid item>
          <Typography variant="h6">
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5} wrap="wrap">
        <Grid item xs={6} md={3}>
          <InfoBoxDataItem title="Accounting integration" value={service}/>
        </Grid>
        <Grid item xs={6} md={3}>
          <InfoBoxDataItem title="Status" value={state}/>
        </Grid>
        <Grid item xs={6} md={3}>
          <InfoBoxDataItem title="Status" value={displayDateTime(last_sync_attempt_at)}/>
        </Grid>
      </Grid>
    </>
  );
};

export { SyncRecord };
