import JSONPretty from 'react-json-pretty';

import { useApi } from '../../hooks';
import { listGLAccountsForCompany } from '../../api/routes';
import { Loader } from '../Common/UI';

type ComponentProps = {
  companyId: string
}

const GLAccountList = ({ companyId }: ComponentProps) => {
  const { items, loading } = useApi<any>(listGLAccountsForCompany(companyId));

  if (loading) {
    return <Loader />;
  }

  return (
    <JSONPretty id="gl-json-pretty" data={items.rows} />
  );
};

export { GLAccountList };
