import { CollapsibleDataGrid, Grid } from '@emburse/embark';

import { useApi } from '../../hooks';
import { listCategoriesForCompany } from '../../api/routes';
import { ISerializedCompanyCategory } from '../../types/api/serialized_company_category';
import { Loader } from '../Common/UI';

const columns = [
  { field: 'id', hide: true },
  { field: 'name', headerName: 'Subcategory', flex: 2 },
  { field: 'filtered_tags', headerName: 'Filtered Tags', flex: 1 }
];

type CategoryListProps = {
  companyId: string;
}

const CategoryList = ({ companyId }: CategoryListProps) => {
  const { items, loading } = useApi<ISerializedCompanyCategory>(listCategoriesForCompany(companyId));
  if (loading) {
    return <Loader />;
  }

  const parseCategory = (category: ISerializedCompanyCategory) => ({
    ...category,
    subcategories: category.subcategories || [],
    filtered_tags: category.filtered_tags ? category.filtered_tags.map((s) => s.name).join(', ') : ''
  });

  const parentCategories = items.rows.filter((category) => !category.parent_id).map((category) => ({
    ...parseCategory(category),
    subcategories: (category.subcategories || []).map(parseCategory)
  }));

  return (
    <>
      {parentCategories.map((category) =>
        <Grid container key={category.id}  spacing={2} className="collapsible-data-grid">
          <Grid item xs={12}>
            <CollapsibleDataGrid
              data-qa="collapsibleGrid"
              rows={category.subcategories}
              columns={columns}
              summary={[
                {
                  text: `${category.name} (${category.subcategories.length})`,
                  xs: 8,
                  align: 'left'
                },
                {
                  text: `${category.filtered_tags}`,
                  xs: 4,
                  className: 'padding-left-10'
                }
              ]}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export { CategoryList };
