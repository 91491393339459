import { ServerDataGrid } from '@emburse/embark';
import { RECEIPTS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { ISerializedReceipt } from '../../types/api/serialized_receipt';
import { ISerializedImage } from '../../types/api/serialized_image';
import { ISerializedTraxoResponse } from '../../types/api/serialized_traxo_response';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { CellParams } from '@material-ui/data-grid';
import { useApi, usePagination } from '../../hooks';
import { listUserReceipts } from '../../api/routes/users';

const receiptColumns = [
  { field: 'id', hide: true },
  { field: 'type', headerName: 'Type', width: 200, sortable: false },
  { field: 'created_at', headerName: 'Create Date', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
  { field: 'state', headerName: 'State', sortable: false },
  { field: 'image', headerName: 'Image', width: 200, sortable: false,
    renderCell: function render(params: CellParams) {
      const image = params.getValue('image') as ISerializedImage;
      return image && <a href={image.url} target="_blank" rel="noreferrer">{image.url}</a> || <></>;
    }
  },
  { field: 'email_subject', headerName: 'Email Subject', width: 200, sortable: false },
  { field: 'error_message', headerName: 'Error Message', width: 300, sortable: false },
  { field: 'company_id', headerName: 'Company', width: 200, sortable: false },
  { field: 'traxo_response', headerName: 'Traxo Response', width: 200, sortable: false,
    renderCell: function render(params: CellParams) {
      const response = params.getValue('traxo_response') as ISerializedTraxoResponse;
      return (<>{response && response.full_response}</>);
    }
  }
];

interface IReceiptsListProps {
  companyId: string;
  userId: string;
}

function UserReceiptsList({ companyId, userId }: IReceiptsListProps) {
  const { limit, offset, onPageChange } = usePagination(RECEIPTS_PER_PAGE);
  const { items, loading } = useApi<ISerializedReceipt>(listUserReceipts(companyId, userId, limit, offset));

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={receiptColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      defaultSortModel={[{ field: 'created_at', sort: 'desc' }]}
      onPageChange={onPageChange}
    />
  );
}

export { UserReceiptsList };
