import { Breadcrumbs as EmbarkBreadcrumbs } from '@emburse/embark';
import { matchPath, useLocation } from 'react-router';

import { routes } from '../App/App';
import { Link } from './Link';

import styles from './Breadcrumbs.module.scss';
import { BreadcrumbTitles } from './BreadcrumbsProvider';

type BreadcrumbsProps = { cachedTitles: BreadcrumbTitles }

const Breadcrumbs = ({ cachedTitles }: BreadcrumbsProps) => {
  const location = useLocation();

  const breadcrumbRoutes: any[] = [];

  routes.forEach(({ path, title }: any) => {
    const match = matchPath(location.pathname, {
      path: path,
      strict: false
    });

    if (!match) {
      return;
    }

    breadcrumbRoutes.push({
      title,
      url: match.url,
      itemId: match.url.split('/').pop()
    });
  });

  return (
    <div className={styles.breadcrumbs}>
      <EmbarkBreadcrumbs>
        {breadcrumbRoutes.map(({ title, url, itemId }: any) => (
          <Link key={url} title={cachedTitles[itemId] || title} to={url} />
        ))}
      </EmbarkBreadcrumbs>
    </div>
  );
};

export { Breadcrumbs };
