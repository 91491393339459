import { SortItem } from '@material-ui/data-grid';

export type ListExpensesWhere = {
  state?: string;
  noteOrVendor?: string;
}

export const getExpenseForCompany = (companyId: string, expenseId: string) => {
  const include = 'include[]=company&include[]=user&include[]=category&include[]=current_reviewer&include[]=expense_tags';
  return `/companies/${companyId}/expenses/${expenseId}?${include}`;
};

export const listExpensesForCompany = (companyId: string, limit: number, offset: number) => {
  const route = `/companies/${companyId}/expenses`;
  const query = `?limit=${limit}&offset=${offset}`;

  return `${route}${query}`;
};

export const listUserExpensesForCompany = (companyId: string, userId: string, limit: number, offset: number, where: ListExpensesWhere) => {
  const route = `/companies/${companyId}/expenses`;

  let query = `?limit=${limit}&offset=${offset}&user_id=${userId}`;

  if (where.state) {
    query += `&state=${where.state}`;
  }
  if (where.noteOrVendor) {
    query += `&note_or_vendor=${where.noteOrVendor}`;
  }
  return `${route}${query}`;
};

export const listUserUnsubmittedExpensesForCompany = (companyId: string, userId: string, limit: number, offset: number) => {
  const route = `/companies/${companyId}/users/${userId}/unsubmitted_expenses`;
  const query = `?limit=${limit}&offset=${offset}&include[]=card_transaction`;

  return `${route}${query}`;
};

export const listCreditExpensesForCompany = (companyId: string, creditId: string, limit: number, offset: number, where: ListExpensesWhere) => {
  const route = `/companies/${companyId}/expenses`;
  let query = `?limit=${limit}&offset=${offset}&credit_id=${creditId}`;

  if (where.state) {
    query += `&state=${where.state}`;
  }
  if (where.noteOrVendor) {
    query += `&note_or_vendor=${where.noteOrVendor}`;
  }

  return `${route}${query}`;
};

export const listDebitExpensesForCompany = (companyId: string, debitId: string, limit: number, offset: number, where: ListExpensesWhere) => {
  const route = `/companies/${companyId}/expenses`;
  let query = `?limit=${limit}&offset=${offset}&debit_id=${debitId}`;

  if (where.state) {
    query += `&state=${where.state}`;
  }
  if (where.noteOrVendor) {
    query += `&note_or_vendor=${where.noteOrVendor}`;
  }

  return `${route}${query}`;
};

export const listActivitiesForExpense = (companyId: string, expenseId: string, limit: number, offset: number, userId?: string) => {
  const route = `/companies/${companyId}/expenses/${expenseId}/activities`;
  let query = `?limit=${limit}&offset=${offset}`;

  if (userId) {
    query += `&user_id=${userId}`;
  }

  return `${route}${query}`;
};

export const listInboxExpensesForUserProfile = (companyId: string, userProfileId: string, limit: number, offset: number, order: SortItem) => {
  const route = `/companies/${companyId}/user_profiles/${userProfileId}/inbox_expenses`;
  let query = `?limit=${limit}&offset=${offset}`;

  if (order) {
    query += `&order=${order.field} ${order.sort}`;
  }

  return `${route}${query}`;
};
