import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { CurrencyFormattedDisplay, Grid, ServerDataGrid } from '@emburse/embark';
import { CellParams } from '@material-ui/data-grid';

import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { ExpenseState, ISerializedExpense } from '../../types/api/serialized_expense';
import { useApi, usePagination } from '../../hooks';
import { listDebitExpensesForCompany } from '../../api/routes';
import { DEBIT_EXPENSES_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { ListExpensesWhere } from '../../api/routes/expenses';
import { Spacer } from '../Common/UI';
import { SearchBar } from '../Common/SearchBar/SearchBar';
import { FilterBar } from '../Common/FilterBar';

interface IUserExpensesProps { currency: string }
interface PageRouteParams { companyId: string, debitId: string }

const stateOptions: { label: string; value: ExpenseState; }[] = [
  { label: 'New', value: 'new' },
  { label: 'Approved', value: 'approved' },
  { label: 'Denied', value: 'denied' },
  { label: 'Approved By Manager', value: 'manager_approved' },
  { label: 'Paid', value: 'paid' }
];

function DebitExpensesList({ currency }: IUserExpensesProps) {
  const [where, setWhere] = useState<ListExpensesWhere>({});
  const { companyId, debitId } = useParams<PageRouteParams>();
  const { limit, offset, onPageChange } = usePagination(DEBIT_EXPENSES_PER_PAGE);
  const { items, loading } = useApi<ISerializedExpense>(listDebitExpensesForCompany(companyId, debitId, limit, offset, where));

  const expenseColumns =  useMemo(() => ([
    { field: 'id', hide: true },
    { field: 'amount', headerName: 'Amount', sortable: false,
      renderCell: function render(params: CellParams) {
        const amount = params.getValue('amount') as number / 100;
        const expenseId: string = params.getValue('id') as string;
        return (
          <Link to={`/companies/${companyId}/debits/${debitId}/expenses/${expenseId}`}>
            <CurrencyFormattedDisplay amount={amount} code={currency} />
          </Link>
        );
      }
    },
    { field: 'created_at', headerName: 'Create Date', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
    { field: 'date', headerName: 'Expense Date', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
    { field: 'source', headerName: 'Source', width: 150, sortable: false },
    { field: 'note', headerName: 'Note', width: 200, sortable: false },
    { field: 'vendor', headerName: 'Vendor', width: 200, sortable: false },
    { field: 'state', headerName: 'State', sortable: false }
  ]), [companyId, debitId, currency]);

  const handleSearch = (searchQuery: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, noteOrVendor: searchQuery });
  };

  const handleFilter = (state: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, state });
  };

  return (
    <>
      <Spacer />
      <Grid container justify="space-between" spacing={2}>
        <Grid item justify="flex-start">
          <SearchBar onSearch={handleSearch} label="Note/Vendor"/>
        </Grid>
        <Grid item>
          <FilterBar label="Filter by state" options={stateOptions} onFilter={handleFilter} />
        </Grid>
      </Grid>
      <Spacer />
      <ServerDataGrid
        rowCount={items.count}
        rows={items.rows}
        columns={expenseColumns}
        autoHeight={true}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        pageSize={limit}
        loading={loading}
        onPageChange={onPageChange}
      />
    </>
  );
}

export { DebitExpensesList };
