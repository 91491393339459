import { SortItem } from '@material-ui/data-grid';

export type ListCompaniesWhere = {
  name?: string;
  state?: string;
}

export const listCompanies = (limit: number, offset: number, order: SortItem, where: ListCompaniesWhere) => {
  const route = '/companies';

  let query = `?limit=${limit}&offset=${offset}`;

  if (order) {
    query += `&order=${order.field} ${order.sort}`;
  }

  if (where.name) {
    query += `&name=${where.name}`;
  }

  if (where.state) {
    query += `&state=${where.state}`;
  }

  return `${route}${query}`;
};

export const getCompany = (companyId: string) => {
  return `/companies/${companyId}?include[]=accounting_integration`;
};

export function listSyncErrorsForCompany(companyId: string, limit = 0, offset = 0) {
  const route = `/companies/${companyId}/sync_errors`;

  const query = `?limit=${limit}&offset=${offset}`;

  return `${route}${query}`;
}

export function listExpenseTagBucketsForCompany(companyId: string, limit: number = 0, offset: number = 0, order?: SortItem) {
  const route = `/companies/${companyId}/expense_tag_buckets`;

  let query = `?limit=${limit}&offset=${offset}`;

  if (order) {
    query += `&order=${order.field} ${order.sort}`;
  }

  return `${route}${query}`;
}

export function listApprovalRoutesForCompany(companyId: string, limit: number = 0, offset: number = 0) {
  const route = `/companies/${companyId}/approval_routes`;

  const query = `?include[]=description&limit=${limit}&offset=${offset}`;

  return `${route}${query}`;
}

export function listExpenseRulesForCompany(companyId: string, limit: number = 0, offset: number = 0) {
  const route = `/companies/${companyId}/expense_rules`;

  const query = `?include[]=description&limit=${limit}&offset=${offset}`;

  return `${route}${query}`;
}

export function listGLAccountsForCompany(companyId: string) {
  return `/companies/${companyId}/gl_accounts`;
}

export const listCategoriesForCompany = (companyId: string, limit: number = 0, offset: number = 0) => {
  const route = `/companies/${companyId}/categories`;
  const query = `?limit=${limit}&offset=${offset}&include[]=subcategories&include[]=parent&include[]=filtered_tags`;

  return `${route}${query}`;
};
