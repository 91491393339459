import { useEffect, useState, ChangeEvent } from 'react';
import { useLocation } from 'react-router';

type TabItem = {
  label: string,
  uri?: string;
}

export function useTabs(tabs: TabItem[]) {
  const location = useLocation();

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const urlPostfix = location.pathname.split('/').pop();

    tabs.forEach(({ uri }, index) => {
      if (uri && uri === urlPostfix) {
        setTabValue(index);
      }
    });
  }, [tabs, location]);

  const onTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return { tabValue, onTabChange };
}
