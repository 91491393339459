import { useState } from 'react';

export function usePagination(initialLimit = 25) {
  const [page, setPage] =  useState<number>(0);
  const [limit, setLimit] =  useState<number>(initialLimit);
  const [offset, setOffset] =  useState<number>(0);

  const onPageChange = (_page: number, _limit: number) => {
    setPage(_page);
    setLimit(_limit);
    setOffset(_page * _limit);
  };

  return { page, limit, offset, onPageChange };
}
