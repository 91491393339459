import { useParams } from 'react-router';
import { ServerDataGrid } from '@emburse/embark';

import { PER_PAGE_OPTIONS, USER_PROFILES_PER_PAGE } from '../../constants';
import { ISerializedUser } from '../../types/api/serialized_user';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { useApi, usePagination } from '../../hooks';
import { listUserDelegates } from '../../api/routes';

const delegateColumns = [
  { field: 'id', hide: true },
  { field: 'companyId', hide: true },
  { field: 'name', headerName: 'Name', width: 200, sortable: false },
  { field: 'email', headerName: 'Email Address', width: 200, sortable: false },
  { field: 'role', headerName: 'Role', width: 100, sortable: false },
  { field: 'bank_account_verified', headerName: 'Bank connection status', width: 200, sortable: false },
  { field: 'created_at', headerName: 'Create Date', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter }
];

interface PageRouteParams { companyId: string, userId: string }

function UserDelegateList() {
  const { companyId, userId } = useParams<PageRouteParams>();

  const { limit, offset, onPageChange } = usePagination(USER_PROFILES_PER_PAGE);
  const { items, loading } = useApi<ISerializedUser>(listUserDelegates(companyId, userId, limit, offset));

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={delegateColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
    />
  );
}

export { UserDelegateList };
