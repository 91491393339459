import { SortItem } from '@material-ui/data-grid';

export type ListCreditsWhere = {
  source?: string;
}

export function listCreditsForCompany(companyId: string, limit: number = 0, offset: number = 0, where: ListCreditsWhere = {}, order?: SortItem) {
  const route = `/companies/${companyId}/credits`;

  let query = `?limit=${limit}&offset=${offset}&include[]=user`;

  if (order) {
    query += `&order=${order.field} ${order.sort}`;
  }

  if (where.source) {
    query += `&source=${where.source}`;
  }

  return `${route}${query}`;
}

export function listCreditsForUser(companyId: string, userId: string, limit: number = 0, offset: number = 0, where: ListCreditsWhere = {}, order?: SortItem) {
  const route = `/companies/${companyId}/users/${userId}/credits`;

  let query = `?limit=${limit}&offset=${offset}`;

  if (order) {
    query += `&order=${order.field} ${order.sort}`;
  }
  if (where.source) {
    query += `&source=${where.source}`;
  }

  return `${route}${query}`;
}

export const getCreditInfoForCompany = (companyId: string, creditId: string) => {
  return `/companies/${companyId}/credits/${creditId}?include[]=company&include[]=user`;
};
