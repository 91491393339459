import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { CurrencyFormattedDisplay, ServerDataGrid, Tooltip } from '@emburse/embark';
import { CellParams, SortItem } from '@material-ui/data-grid';

import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { ISerializedExpense } from '../../types/api/serialized_expense';
import { useApi, usePagination } from '../../hooks';
import { USER_PROFILE_EXPENSES_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { listInboxExpensesForUserProfile } from '../../api/routes/expenses';

interface IUserExpensesProps { currency: string }
interface PageRouteParams { companyId: string, userProfileId: string }

const defaultSortModel: SortItem[] = [{
  field: 'date',
  sort: 'desc'
}];

function UserProfileInboxExpenseList({ currency }: IUserExpensesProps) {
  const { companyId, userProfileId } = useParams<PageRouteParams>();
  const [order, setOrder] =  useState<SortItem>({ field: 'created_at', sort: 'desc' });
  const { limit, offset, onPageChange } = usePagination(USER_PROFILE_EXPENSES_PER_PAGE);
  const { items, loading } = useApi<ISerializedExpense>(listInboxExpensesForUserProfile(companyId, userProfileId, limit, offset, order));

  const expenseColumns =  useMemo(() => ([
    { field: 'id', hide: true },
    { field: 'amount', headerName: 'Amount',
      renderCell: function render(params: CellParams) {
        const amount = params.getValue('amount') as number / 100;
        return <CurrencyFormattedDisplay amount={amount} code={currency} />;
      }
    },
    { field: 'created_at', headerName: 'Created at', width: 200, valueFormatter: dateTimeFormatter },
    { field: 'date', headerName: 'Expense Date', width: 200, valueFormatter: dateTimeFormatter },
    { field: 'note', headerName: 'Note', width: 300,
      renderCell: function render(params: any) {
        const note = params.getValue('note') as string;
        return <Tooltip title={note}><span>{note}</span></Tooltip>;
      } },
    { field: 'vendor', headerName: 'Vendor', width: 350,
      renderCell: function render(params: any) {
        const vendor = params.getValue('vendor') as string;
        return <Tooltip title={vendor}><span>{vendor}</span></Tooltip>;
      }
    }
  ]), [currency]);

  const onSortChange = (sortData: SortItem[]) => {
    setOrder((sortData && sortData[0]) || null);
  };

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={expenseColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
      onSortChange={onSortChange}
      defaultSortModel={defaultSortModel}
    />
  );
}

export { UserProfileInboxExpenseList };
