import styles from './Loader.module.scss';

const Loader = () => {
  return (
    <div className={styles.abaLoader}>
      <div className={styles.abaLoaderWrapper}>
        <svg xmlns="http://www.w3.org/2000/svg" className={styles.abaLoaderSvg} viewBox="22 22 44 44">
          <circle className={styles.abaLoaderCircle} cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" stroke="currentColor"></circle>
        </svg>
      </div>
    </div>
  );
};

export { Loader };
