import { useParams } from 'react-router';
import { Card, Grid, Typography, Tabs, TabPanel } from '@emburse/embark';

import { useApi, useTabs } from '../../hooks';
import { ISerializedCardProgram } from '../../types/api/serialized_card_program';
import { getCardProgramForCompany } from '../../api/routes';
import { displayDateTime } from '../../helpers/text_formatters';
import { CardProgramSubAccountsList, CardList } from '.';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { Loader } from '../Common/UI';

const cardTabs = [
  { label: 'Corporate Cards', uri: 'cards' },
  { label: 'Sub Accounts' }
];

const CardProgramInfo = () => {
  const { companyId, cardProgramId } = useParams<{ companyId: string, cardProgramId: string }>();
  const { item, loading } = useApi<ISerializedCardProgram>(getCardProgramForCompany(companyId, cardProgramId));
  const { tabValue, onTabChange } = useTabs(cardTabs);

  if (loading) {
    return <Loader />;
  }

  if (!item) {
    return <div>no item</div>;
  }

  const {
    number,
    company,
    name,
    connected_on,
    pullback_date,
    state,
    sub_accounts
  } = item;

  return (
    <>
      <Typography variant="h4">
        {name}
      </Typography>
      <Card className="card-block">
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item>
            <Typography variant="h6">
              Card Program Info
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5} wrap="wrap">
          {number &&
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Number" value={number}/>
          </Grid>
          }
          {company &&
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Company" value={company.name}/>
          </Grid>
          }
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="State" value={state}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Connected On" value={displayDateTime(connected_on)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Pullback date" value={typeof pullback_date === 'string' ? pullback_date : displayDateTime(pullback_date)}/>
          </Grid>
        </Grid>
      </Card>
      <Tabs
        id="cards-tab-bar"
        tabs={cardTabs}
        initialIndex={tabValue}
        onChange={onTabChange}
      />
      <TabPanel value={tabValue} index={0}>
        <CardList companyId={companyId} cardProgramId={cardProgramId} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {sub_accounts && <CardProgramSubAccountsList subAccounts={sub_accounts} />}
      </TabPanel>
    </>
  );
};

export { CardProgramInfo };
