import { DataGrid } from '@emburse/embark';

import { PER_PAGE_OPTIONS, USER_EXPENSE_TAGS_PER_PAGE } from '../../constants';
import { ISerializedExpenseTagBucket } from  '../../types/api/serialized_expense_tag_bucket';
import { useApi } from '../../hooks';
import { listExpenseTagBucketsForUser } from '../../api/routes';
import { disabledFormatter, yesNoFormatter } from '../../helpers/datagrid_formatters';

const tagBucketsColumns = [
  { field: 'id', hide: true },
  { field: 'name', headerName: 'Name', width: 240, sortable: false },
  { field: 'bucket_type', headerName: 'Field Type', width: 120, sortable: false },
  { field: 'input_type', headerName: 'Dropdown/text', width: 120, sortable: false },
  { field: 'accounting_sync_type', headerName: 'Mapped to Accounting field?', width: 240, sortable: false },
  { field: 'user_can_set_value', headerName: 'User Editable', width: 100, valueFormatter: yesNoFormatter, sortable: false },
  { field: 'disabled', headerName: 'Status', width: 100, valueFormatter: disabledFormatter, sortable: false },
  { field: 'scoped_by', headerName: 'Scoped By', width: 240, sortable: false },
  { field: 'items', headerName: 'Items', width: 100, sortable: false },
  { field: 'state', headerName: 'State', flex: 1, sortable: false }
];

interface IExpenseTagBucketListProps { companyId: string, userId: string }

function UserExpenseTagBucketList({ companyId, userId }: IExpenseTagBucketListProps) {
  const { items, loading } = useApi<ISerializedExpenseTagBucket>(listExpenseTagBucketsForUser(companyId, userId));

  return (
    <DataGrid
      rows={items.rows}
      columns={tagBucketsColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      loading={loading}
      pageSize={USER_EXPENSE_TAGS_PER_PAGE}
    />
  );
}

export { UserExpenseTagBucketList };
