import { useParams } from 'react-router';
import { Card, CurrencyFormattedDisplay, Grid, Typography, TabPanel, Tabs } from '@emburse/embark';

import { getDebitForCompany } from '../../api/routes';
import { displayDateTime } from '../../helpers/text_formatters';
import { useApi, useTabs } from '../../hooks';
import { ISerializedDebit } from '../../types/api/serialized_debit';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { Loader } from '../Common/UI';
import { DebitExpensesList } from '../Expenses';
import { SyncRecord } from '../SyncRecord';

const debitTabs = [
  { label: 'Expenses' }
];

interface PageRouteParams {
  companyId: string;
  debitId: string;
}

function DebitInfo() {
  const { companyId, debitId } = useParams<PageRouteParams>();
  const { tabValue, onTabChange } = useTabs(debitTabs);
  const { item, loading } = useApi<ISerializedDebit>(getDebitForCompany(companyId, debitId));

  if (loading) {
    return <Loader />;
  }

  if (!item) {
    return <div>no item</div>;
  }

  const {
    id,
    processor,
    company,
    source,
    amount,
    status,
    created_at,
    estimated_success_date,
    sync_record
  } = item;

  const currencyCode = company?.currency_code || '';

  return (
    <>
      <Typography variant="h4">
        ID: {id}
      </Typography>
      <Card className="card-block">
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item>
            <Typography variant="h6">
              Debit Information
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Processor" value={processor}/>
          </Grid>
          {company &&
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Company Name" value={company.name}/>
          </Grid>
          }
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Source" value={source}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Amount">
              <CurrencyFormattedDisplay amount={amount / 100} code={currencyCode} />
            </InfoBoxDataItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Status" value={status}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Created At" value={displayDateTime(created_at)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Succeeded At Time" value={displayDateTime(estimated_success_date)}/>
          </Grid>
        </Grid>
        {sync_record && <SyncRecord syncRecord={sync_record} />}
      </Card>
      <Tabs id="debits-tab-bar"
        tabs={debitTabs}
        onChange={onTabChange}
        initialIndex={tabValue}
      />
      <TabPanel value={tabValue} index={0}>
        <DebitExpensesList currency={currencyCode}/>
      </TabPanel>
    </>
  );
}

export { DebitInfo };
