import { ServerDataGrid } from '@emburse/embark';

import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { ISerializedExpenseActivity } from '../../types/api/serialized_expense_activity';
import { useApi, usePagination } from '../../hooks';
import { listActivitiesForExpense } from '../../api/routes';
import { ITEMS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';

const activityColumns = [
  { field: 'id', hide: true },
  { field: 'created_at', headerName: 'Create Date', width: 200, valueFormatter: dateTimeFormatter },
  { field: 'string', headerName: 'Description', flex: 1 }
];

interface ExpenseActivityListProps { companyId: string, userId?: string, expenseId: string }

const ExpenseActivityList = ({ companyId, userId, expenseId }: ExpenseActivityListProps) => {
  const { limit, offset, onPageChange } = usePagination(ITEMS_PER_PAGE);
  const { items, loading } = useApi<ISerializedExpenseActivity>(listActivitiesForExpense(companyId, expenseId, limit, offset, userId));

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={activityColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
    />
  );
};

export { ExpenseActivityList };
