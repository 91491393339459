import { DataGrid, Tooltip, Typography } from '@emburse/embark';
import { SortItem } from '@material-ui/data-grid';
import { CellParams } from '@material-ui/data-grid';

import { NOTIFICATIONS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { useApi } from '../../hooks';
import { listNotificationsForUserProfile } from '../../api/routes';
import { ISerializedNotification } from '../../types/api/serialized_notification';
import { yesNoFormatter } from '../../helpers/datagrid_formatters';

const columns = [
  { field: 'id', hide: true },
  {
    field: 'title', headerName: 'Title', width: 280, sortable: true,
    renderCell: function render(params: CellParams) {
      const title = params.value as string;
      return (
        <Tooltip title={title}><Typography variant="body2" noWrap>{title}</Typography></Tooltip>
      );
    }
  },
  {
    field: 'type', headerName: 'Type', width: 200, sortable: true,
    renderCell: function render(params: CellParams) {
      const type = params.value as string;
      return (
        <Tooltip title={type}><Typography variant="body2" noWrap>{type}</Typography></Tooltip>
      );
    }
  },
  { field: 'email_available', headerName: 'Email Available', width: 120, sortable: false, valueFormatter: yesNoFormatter },
  { field: 'email_enabled', headerName: 'Email Enabled', width: 120, sortable: false, valueFormatter: yesNoFormatter },
  { field: 'push_available', headerName: 'Push Available', width: 120, sortable: false, valueFormatter: yesNoFormatter },
  { field: 'push_enabled', headerName: 'Push Enabled', width: 120, sortable: false, valueFormatter: yesNoFormatter },
  { field: 'slackbot_enabled', headerName: 'Slackbot Enabled', width: 130, sortable: false, valueFormatter: yesNoFormatter },
  { field: 'visibility', headerName: 'Visibility', width: 120, sortable: true }
];

const defaultSortModel: SortItem[] = [{
  field: 'title',
  sort: 'asc'
}];

type NotificationListProps = { companyId: string, userProfileId: string };

function NotificationList({ companyId, userProfileId }: NotificationListProps) {
  const { items, loading } = useApi<ISerializedNotification>(listNotificationsForUserProfile(companyId, userProfileId));

  // Add unique id
  const itemsWithId = items.rows.map((item) => ({ ...item, id: item.type }));

  return (
    <DataGrid
      rows={itemsWithId}
      columns={columns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={NOTIFICATIONS_PER_PAGE}
      defaultSortModel={defaultSortModel}
      loading={loading}
    />
  );
}

export { NotificationList };
