import { Link } from 'react-router-dom';
import { CellParams } from '@material-ui/data-grid';
import { ServerDataGrid } from '@emburse/embark';
import { PROGRAMS_PER_PAGE } from '../../constants';
import { ISerializedCardProgram } from '../../types/api/serialized_card_program';
import { useApi, usePagination } from '../../hooks';
import { listCardProgramsForCompany } from '../../api/routes';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';

const creditColumns = [
  { field: 'id', hide: true, sortable: false },
  {
    field: 'name', headerName: 'Name', width: 240, sortable: false,
    renderCell: function render(params: CellParams) {
      const cardProgramId = params.getValue('id') as string;
      const companyId = params.getValue('company_id') as string;
      return (
        <Link to={`/companies/${companyId}/card_programs/${cardProgramId}`}>{params.getValue('name')}</Link>
      );
    }
  },
  { field: 'connected_on', headerName: 'Connected On', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
  { field: 'pullback_date', headerName: 'Pullback Date', width: 240, sortable: false },
  { field: 'state', headerName: 'State', width: 300, sortable: false }
];

interface ICardProgramListProps {
  companyId: string;
}

function CardProgramList({ companyId }: ICardProgramListProps) {
  const { limit, offset, onPageChange } = usePagination(PROGRAMS_PER_PAGE);
  const { items, loading } = useApi<ISerializedCardProgram>(listCardProgramsForCompany(companyId, limit, offset));

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={creditColumns}
      autoHeight={true}
      rowsPerPageOptions={[5, 10, 25, 50]}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
    />
  );
}

export { CardProgramList };
