import { DataGrid, Chip } from '@emburse/embark';
import { CellParams, SortItem, ValueFormatterParams } from '@material-ui/data-grid';

import { EXPENSE_TAGS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { ISerializedExpenseTag } from '../../types/api/serialized_expense_tag';
import { displayYesNo } from '../../helpers/text_formatters';

const expenseTagsColumns = [
  { field: 'id', hide: true },
  { field: 'expense_tag_bucket_name', headerName: 'Custom Field', width: 200 },
  { field: 'name', headerName: 'Field Item/Tag', width: 200 },
  {
    field: 'disabled',
    headerName: 'Tag Active',
    width: 200,
    valueFormatter: (({ value }: ValueFormatterParams) => displayYesNo(!value))
  },
  {
    field: 'class_id',
    headerName: 'Mapped to Class',
    width: 400,
    sortable: false,
    renderCell: function render(params: CellParams) {
      const classId = params.getValue('class_id') as string;
      const accountingSyncType = params.getValue('accounting_sync_type') as string;
      return (
        <>
          {displayYesNo(Boolean(classId))} &nbsp;
          {classId && accountingSyncType && <Chip label={accountingSyncType} size="small" variant="outlined"/>} &nbsp;
          {classId && <Chip label={classId} size="small" variant="outlined"/>}
        </>
      );
    }
  }
];

const defaultSortModel: SortItem[] = [{
  field: 'name',
  sort: 'asc'
}];

interface IExpenseTagsListProps {
  expenseTags: ISerializedExpenseTag[];
}

function ExpenseTagsList({ expenseTags = [] }: IExpenseTagsListProps) {
  return (
    <DataGrid
      rows={expenseTags}
      columns={expenseTagsColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={EXPENSE_TAGS_PER_PAGE}
      defaultSortModel={defaultSortModel}
    />
  );
}

export { ExpenseTagsList };
