import { ServerDataGrid, Tooltip } from '@emburse/embark';

import { ITEMS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { useApi, usePagination } from '../../hooks';
import { listApprovalRoutesForCompany } from '../../api/routes';
import { ISerializedApprovalRoute } from '../../types/api/serialized_approval_route';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { SortItem } from '@material-ui/data-grid';

const approvalRouteColumns = [
  { field: 'id', hide: true },
  { field: 'created_at', headerName: 'Created On', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
  {
    field: 'description', headerName: 'Approval Route', flex: 1, sortable: false,
    renderCell: function render(params: any) {
      const description = params.getValue('description') as string;
      return (
        <Tooltip title={description}><span>{description}</span></Tooltip>
      );
    }
  }
];

type ComponentProps = {
  companyId: string
}

const ApprovalRouteList = ({ companyId }: ComponentProps) => {
  const { limit, offset, onPageChange } = usePagination(ITEMS_PER_PAGE);
  const { items, loading } = useApi<ISerializedApprovalRoute>(listApprovalRoutesForCompany(companyId, limit, offset));

  const defaultSortModel: SortItem[] = [{
    field: 'created_at',
    sort: 'desc'
  }];

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      pageSize={limit}
      columns={approvalRouteColumns}
      autoHeight={true}
      loading={loading}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      onPageChange={onPageChange}
      defaultSortModel={defaultSortModel}
    />
  );
};

export { ApprovalRouteList };
