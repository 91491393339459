import { DataGrid } from '@emburse/embark';
import { CellParams } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { SUB_ACCOUNTS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { ISerializedCard } from '../../types/api/serialized_card';

const subAccountColumns = [
  { field: 'id', hide: true },
  { field: 'name', headerName: 'Account Name', width: 250, sortable: false },
  {
    field: 'user_id',
    headerName: 'User',
    width: 350,
    sortable: false,
    renderCell: function render(params: CellParams) {
      const userId = params.getValue('user_id') as string;
      const companyId = params.getValue('company_id') as string;
      return (
        <Link to={`/companies/${companyId}/users/${userId}`}>
          {userId}
        </Link>
      );
    }
  },
  { field: 'state', headerName: 'State', width: 300, sortable: false }
];

interface ICardProgramSubAccountsListProps {
  subAccounts: ISerializedCard[];
}

function CardProgramSubAccountsList({ subAccounts }: ICardProgramSubAccountsListProps) {
  return (
    <DataGrid
      rows={subAccounts}
      columns={subAccountColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={SUB_ACCOUNTS_PER_PAGE}
    />
  );
}

export { CardProgramSubAccountsList };
