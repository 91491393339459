import { Link } from 'react-router-dom';
import { Grid, ServerDataGrid } from '@emburse/embark';
import { CellParams } from '@material-ui/data-grid';

import { PER_PAGE_OPTIONS, USERS_PER_PAGE } from '../../constants';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { CSVExport } from '../Common/CSVExport';
import { formHeaders } from '../../helpers/csv';
import { ISerializedUser } from '../../types/api/serialized_user';
import { usePagination, useApi } from '../../hooks';
import { SearchBar } from '../Common/SearchBar/SearchBar';
import { Spacer } from '../Common/UI';
import { useState } from 'react';
import { listUsersForCompany, ListUsersWhere } from '../../api/routes/users';

const userColumns = [
  { field: 'id', hide: true },
  { field: 'company_id', hide: true },
  {
    field: 'name', headerName: 'Name', width: 300, shortable: false,
    renderCell: function render(params: CellParams) {
      const userId = params.getValue('id') as string;
      const companyId = params.getValue('company_id') as string;
      return (
        <Link to={`/companies/${companyId}/users/${userId}`}>{params.getValue('name')}</Link>
      );
    }
  },
  { field: 'email', headerName: 'Email Address', width: 350, sortable: false },
  { field: 'role', headerName: 'Role', sortable: false },
  { field: 'bank_account_verified', headerName: 'Bank connection status', width: 200, sortable: false },
  { field: 'created_at', headerName: 'Create Date', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter }
];

interface IUserListProps {
  companyId: string;
}

const UserList = ({ companyId }: IUserListProps) => {
  const [where, setWhere] = useState<ListUsersWhere>({});
  const { limit, offset, onPageChange } = usePagination(USERS_PER_PAGE);
  const { items, loading } = useApi<ISerializedUser>(listUsersForCompany(companyId, limit, offset, where));

  const handleSearch = (searchQuery: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, name: searchQuery });
  };

  return (
    <>
      <Spacer />
      <Grid container justify="space-between" spacing={2}>
        <Grid item>
          <SearchBar onSearch={handleSearch} label="User Name"/>
        </Grid>
      </Grid>

      <ServerDataGrid
        rowCount={items.count}
        rows={items.rows}
        columns={userColumns}
        autoHeight={true}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        pageSize={limit}
        loading={loading}
        onPageChange={onPageChange}
      />
      <CSVExport
        route={listUsersForCompany(companyId, limit, offset, where)}
        headers={formHeaders(userColumns)}
        title="Export Users to CSV"
      />
    </>
  );
};

export { UserList };
