import { CurrencyFormattedDisplay, ServerDataGrid, Tooltip } from '@emburse/embark';
import { CellParams } from '@material-ui/data-grid';

import { PER_PAGE_OPTIONS, SYNC_ERRORS_PER_PAGE } from '../../constants';
import { ISerializedAccountingSyncRecord } from '../../types/api/serialized_accounting_sync_record';
import { useApi, usePagination } from '../../hooks';
import { listSyncErrorsForCompany } from '../../api/routes';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';

const creditColumns = [
  { field: 'id', hide: true },
  {
    field: 'amount', headerName: 'Amount', width: 140, sortable: false,
    renderCell: function render(params: CellParams) {
      const amount = params.getValue('amount') as number / 100;
      const code = params.getValue('company_currency_code') as string;
      return (
        <CurrencyFormattedDisplay amount={amount} code={code} />
      );
    }
  },
  { field: 'submission_date', headerName: 'Submission Date', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
  { field: 'expense_date', headerName: 'Expense Date', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
  { field: 'type', headerName: 'Type', width: 200, sortable: false },
  {
    field: 'details', headerName: 'Details', flex: 1, sortable: false,
    renderCell: function render(params: any) {
      const details = params.getValue('details').join(',') as string;
      return (
        <Tooltip title={details}><span>{details}</span></Tooltip>
      );
    }
  },
  {
    field: 'error_message', headerName: 'Sync Error', flex: 1, sortable: false,
    renderCell: function render(params: any) {
      const error_message = params.getValue('error_message') as string;
      return (
        <Tooltip title={error_message}><span>{error_message}</span></Tooltip>
      );
    }
  }
];

interface ISyncErrorListProps {
  companyId: string;
}

function SyncErrorList({ companyId }: ISyncErrorListProps) {
  const { limit, offset, onPageChange } = usePagination(SYNC_ERRORS_PER_PAGE);
  const { items, loading } = useApi<ISerializedAccountingSyncRecord>(listSyncErrorsForCompany(companyId, limit, offset));

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={creditColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
    />
  );
}

export { SyncErrorList };
