import { ChangeEvent } from 'react';
import { Autocomplete, makeStyles } from '@emburse/embark';

const useStyles = makeStyles({
  filterDropdown: {
    width: '200px'
  }
});

interface IOptions {
  label: string;
  value: string;
}

interface FilterBarProps {
  label: string;
  options: IOptions[]
  onFilter: (value: string) => void;
}

const FilterBar = ({ label, options, onFilter }: FilterBarProps) => {
  const handleChange = (e: ChangeEvent<{}>, option: IOptions | null) => {
    onFilter(option?.value || '');
  };

  const classes = useStyles();
  return (
    <Autocomplete
      id="fields"
      data-testid="autocomplete-filter"
      label={label || 'Fields'}
      className={classes.filterDropdown}
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
    />
  );
};

export { FilterBar };
