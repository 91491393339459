import { ColDef } from '@material-ui/data-grid';

type FormHeadersOptions = {
  omit?: string[];
}

export const formHeaders = (columns: ColDef[], options: FormHeadersOptions = {}) => {
  const headers: { key: string, label: string }[] = [];
  const omit = options.omit;

  columns.forEach((column) => {
    if (omit?.includes(column.field)) {
      return;
    }

    headers.push({ key: column.field, label: column.headerName || column.field });
  });

  return headers;
};
