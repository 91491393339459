import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { CurrencyFormattedDisplay, ServerDataGrid } from '@emburse/embark';
import { CellParams } from '@material-ui/data-grid';

import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { ISerializedExpense } from '../../types/api/serialized_expense';
import { useApi, usePagination } from '../../hooks';
import { listExpensesForCompany } from '../../api/routes';
import { EXPENSES_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';

interface IUserExpensesProps { currency: string }
interface PageRouteParams { companyId: string }

const CompanyExpenseList = ({ currency }: IUserExpensesProps) => {
  const { companyId } = useParams<PageRouteParams>();
  const { limit, offset, onPageChange } = usePagination(EXPENSES_PER_PAGE);
  const { items, loading } = useApi<ISerializedExpense>(listExpensesForCompany(companyId, limit, offset));

  const expenseColumns =  useMemo(() => ([
    { field: 'id', hide: true },
    { field: 'company_id', hide: true },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 100,
      sortable: false,
      renderCell: function render(params: CellParams) {
        const amount = params.getValue('amount') as number / 100;
        const expenseId: string = params.getValue('id') as string;
        return (
          <Link to={`/companies/${companyId}/expenses/${expenseId}`}>
            <CurrencyFormattedDisplay amount={amount} code={currency} />
          </Link>
        );
      }
    },
    { field: 'state', headerName: 'State', sortable: false },
    { field: 'created_at', headerName: 'Create Date', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
    { field: 'date', headerName: 'Expense Date', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
    { field: 'reviewed_at', headerName: 'Review Date', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
    { field: 'sync_status', headerName: 'Sync status', sortable: false }
  ]), [companyId, currency]);

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={expenseColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
    />
  );
};

export { CompanyExpenseList };
