import { DataGrid } from '@emburse/embark';
import { FEATURES_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';

const featureColumns = [
  { field: 'id', hide: true },
  { field: 'name', headerName: 'Feature', width: 500, sortable: false },
  { field: 'status', headerName: 'Status', width: 200, sortable: false }
];

type FeaturesData = {
  mileage_rate_type: 'irs' | 'custom' | 'off',
  mileage_rate: any,
  attendees: boolean,
  attendees_split: boolean,
  billable: boolean,
  approved_expenses_uneditable: boolean,
  allow_self_approval: boolean
}

type FeatureListProps = {
  featuresData: FeaturesData;
}

const formFeaturesList = (data : FeaturesData) => {
  const {
    mileage_rate_type,
    mileage_rate,
    attendees,
    attendees_split,
    billable,
    approved_expenses_uneditable,
    allow_self_approval
  } = data;
  const features = [{
    name: 'Mileage Reimbursement Rate',
    status: mileage_rate_type === 'off' ? 'DISABLED' : 'ENABLED'
  },{
    name: 'Attendees Listed',
    status: attendees ? 'ENABLED' : 'DISABLED'
  },{
    name: 'Split Expense by No. Attendees',
    status: attendees && attendees_split ? 'ENABLED' : 'DISABLED'
  },{
    name: 'Billable Expenses',
    status: billable ? 'ENABLED' : 'DISABLED'
  },{
    name: 'Prevent Non-Admins from Editing of Approved Expenses',
    status: approved_expenses_uneditable ? 'ENABLED' : 'DISABLED'
  },{
    name: 'Prevent Admins from Approving Own Expenses',
    status: allow_self_approval ? 'DISABLED' : 'ENABLED'
  }];

  if (['custom', 'irs'].includes(mileage_rate_type)) {
    features.splice(1, 0,{
      name: 'Reimbursement Rate Per Mile',
      status: mileage_rate > 0 ? mileage_rate.toString() + ' cents/mile' : 'N/A'
    },{
      name: 'Custom or Standard Rate',
      status: mileage_rate_type  === 'custom' ? 'Custom' : 'Standard (IRS)'
    });
  }
  return features;
};

const CompanyFeatureList = ({ featuresData }: FeatureListProps) => {
  const features = formFeaturesList(featuresData);
  return (
    <DataGrid
      rows={features.map((item, index) => ({ ...item, id: index }))}
      columns={featureColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={FEATURES_PER_PAGE}
    />
  );
};

export { CompanyFeatureList };
