import { useParams } from 'react-router';
import { Card, CurrencyFormattedDisplay, Grid, TabPanel, Tabs, Typography } from '@emburse/embark';

import { displayDateTime } from '../../helpers/text_formatters';
import { ISerializedCredit } from '../../types/api/serialized_credit';
import { useApi } from '../../hooks';
import { getCreditInfoForCompany } from '../../api/routes';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { Loader } from '../Common/UI';
import { ChangeEvent, useState } from 'react';
import { CreditExpensesList } from '../Expenses';

const creditTabs = [
  { label: 'Expenses' }
];

interface PageRouteParams {
  companyId: string;
  creditId: string;
}

function CreditInfo() {
  const { companyId, creditId } = useParams<PageRouteParams>();
  const [tabValue, setTabValue] = useState(0);
  const { item, loading } = useApi<ISerializedCredit>(getCreditInfoForCompany(companyId, creditId));

  if (loading) {
    return <Loader />;
  }

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  if (!item) {
    return <div>no credit</div>;
  }

  const {
    id,
    company,
    debit_id,
    user,
    amount,
    status,
    created_at,
    started_at
  } = item;

  const currencyCode = user && user.currency_code || '';

  return (
    <>
      <Typography variant="h4">
        ID: {id}
      </Typography>
      <Card className="card-block">
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item>
            <Typography variant="h6">
              Credit Information
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5} wrap="wrap">
          { company &&
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Company" value={company.name}/>
          </Grid>
          }
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Debit ID" value={debit_id}/>
          </Grid>
          { user &&
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="User Name" value={user.name}/>
          </Grid>
          }
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Credit amount">
              <CurrencyFormattedDisplay amount={amount / 100} code={currencyCode} />
            </InfoBoxDataItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Status" value={status}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Created At" value={displayDateTime(created_at)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Started At" value={displayDateTime(started_at)}/>
          </Grid>
        </Grid>
      </Card>
      <Tabs id="invoice-tab-bar" tabs={creditTabs} onChange={handleTabChange} />
      <TabPanel value={tabValue} index={0}>
        <CreditExpensesList currency={currencyCode}/>
      </TabPanel>
    </>
  );
}

export { CreditInfo };
