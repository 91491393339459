import { Link as EmbarkLink } from '@emburse/embark';
import { useHistory } from 'react-router';

type LinkProps = {
  title: string;
  to: string;
}

const Link = ({ title, to }: LinkProps) => {
  const history = useHistory();

  const handleRedirect = (e: any) => {
    e.preventDefault();
    history.push(to);
  };

  return (
    <EmbarkLink color="inherit" underline="hover" onClick={handleRedirect}>
      {title}
    </EmbarkLink>
  );
};

export { Link };
