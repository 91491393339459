import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CellParams, SortItem } from '@material-ui/data-grid';
import { ServerDataGrid, CurrencyFormattedDisplay, Grid } from '@emburse/embark';

import { DEBITS_PER_PAGE, PER_PAGE_OPTIONS } from '../../constants';
import { FilterBar } from '../Common/FilterBar';
import { DebitSource, ISerializedDebit } from '../../types/api/serialized_debit';
import { useApi, usePagination } from '../../hooks';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';
import { listDebitsForCompany, ListDebitsWhere } from '../../api/routes/debits';
import { Spacer } from '../Common/UI';

const debitColumns = [
  { field: 'id', hide: true },
  { field: 'company_id', hide: true },
  {
    // sortable: false,
    field: 'amount', headerName: 'Amount', width: 140,
    renderCell: function render(params: CellParams) {
      const amount = params.getValue('amount') as number / 100;
      const code = params.getValue('company_currency_code') as string;
      const companyId = params.getValue('company_id') as string;
      const debitId = params.getValue('id') as string ;
      return (
        <Link to={`/companies/${companyId}/debits/${debitId}`}>
          <CurrencyFormattedDisplay amount={amount} code={code} />
        </Link>
      );
    }
  },
  { field: 'created_at', headerName: 'Debit Date', type: 'dateTime', width: 240, valueFormatter: dateTimeFormatter },
  { field: 'source', headerName: 'Source', width: 300 },
  { field: 'status', headerName: 'Status', width: 300 }
];

const stateOptions: { label: string; value: DebitSource; }[] = [
  { label: 'Bill', value: 'bill' },
  { label: 'Payment', value: 'payment' },
  { label: 'Reimbursement', value: 'reimbursement' }
];

interface IDebitListProps {
  companyId: string;
}

function DebitList({ companyId }: IDebitListProps) {
  const [where, setWhere] = useState<ListDebitsWhere>({});
  const [order, setOrder] =  useState<SortItem>({ field: 'created_at', sort: 'desc' });
  const { limit, offset, onPageChange } = usePagination(DEBITS_PER_PAGE);
  const { items, loading } = useApi<ISerializedDebit>(listDebitsForCompany(companyId, limit, offset, order, where));

  const onSortChange = (sortData: SortItem[]) => {
    setOrder((sortData && sortData[0]) || null);
  };

  const handleFilter = (source: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, source });
  };

  return (
    <>
      <Spacer />
      <Grid container justify="flex-end" spacing={2}>
        <Grid item>
          <FilterBar label="Filter by source" options={stateOptions} onFilter={handleFilter} />
        </Grid>
      </Grid>
      <ServerDataGrid
        rowCount={items.count}
        rows={items.rows}
        columns={debitColumns}
        autoHeight={true}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        pageSize={limit}
        loading={loading}
        defaultSortModel={[{ field: 'created_at', sort: 'desc' }]}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
      />
    </>
  );
}

export { DebitList };
