export const designToken = {
  palette: {
    primary: {
      main: '#007db8',
      dark: '#004B6C',
      light: '#33BBEF',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#38496B',
      dark: '#0A2240',
      light: '#657499',
      contrastText: '#FFFFFF'
    },
    info: {
      main: '#0097DC',
      dark: '#0069AA',
      light: '#61C7FF',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
      contrastText: 'rgba(0,0,0,87)'
    },
    warning: {
      main: '#FF9800',
      dark: '#C77700',
      light: '#FFB547',
      contrastText: 'rgba(0,0,0,87)'
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#222222',
      secondary: '#7E7E7E',
      disabled: '#B1B1B1'
    },
    divider: '#212121',
    grey: {
      '50': '#FAFAFA',
      '100': '#F5F5F5',
      '200': '#EEEEEE',
      '300': '#E0E0E0',
      '400': '#BDBDBD',
      '500': '#9E9E9E',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      'A100': '#D5D5D5',
      'A200': '#AAAAAA',
      'A400': '#303030',
      'A700': '#616161'
    }
  },
  typography: {
    fontFamily: 'Lato',
    h1: {
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '96px',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '60px',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '48px',
      letterSpacing: 0
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '34px',
      letterSpacing: '0.25px',
      lineHeight: '42px',
      paddingTop: '42px',
      paddingBottom: '42px'
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      letterSpacing: 0
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      letterSpacing: '0.15px'
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      letterSpacing: '0.15px',
      lineHeight: '22px'
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '0.1px'
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      letterSpacing: '0.15px',
      lineHeight: '22px'
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: '0.15px'
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      letterSpacing: '0.4px'
    },
    caption: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      letterSpacing: '0.4px'
    },
    overline: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      letterSpacing: '1px'
    }
  }
};
